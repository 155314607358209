import React, { useEffect, useState } from "react";
import { storage } from "./firebaseConfig";
import { ref, listAll, getDownloadURL, deleteObject } from "firebase/storage";
import { Box, Typography, Grid, Button } from "@mui/material";

const ViewVideos = () => {
  const [videoUrls, setVideoUrls] = useState([]);
  const [videoRefs, setVideoRefs] = useState([]);

  useEffect(() => {
    const fetchVideos = async () => {
      try {
        const listRef = ref(storage, "videos/");
        const res = await listAll(listRef);
        const urls = await Promise.all(res.items.map((item) => getDownloadURL(item)));
        setVideoUrls(urls);
        setVideoRefs(res.items);
      } catch (error) {
        console.error("Error fetching videos:", error);
      }
    };

    fetchVideos();
  }, []);

  const handleDelete = async (index) => {
    try {
      const videoRef = videoRefs[index];
      await deleteObject(videoRef);
      setVideoUrls((prevUrls) => prevUrls.filter((_, i) => i !== index));
      setVideoRefs((prevRefs) => prevRefs.filter((_, i) => i !== index));
    } catch (error) {
      console.error("Error deleting video:", error);
    }
  };

  return (
    <Box sx={{ p: 4, height: '80vh', overflow: 'auto' }}>
      <Typography variant="h4" gutterBottom>
        View Videos
      </Typography>
      <Grid container spacing={2}>
        {videoUrls.map((url, index) => (
          <Grid item key={index} xs={12} sm={6} md={4}>
            <video
              width="100%"
              height="100%"
              controls
              controlsList="nodownload"
              style={{ maxWidth: '300px', maxHeight: '300px' }}
            >
              <source src={url} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => handleDelete(index)}
              style={{ marginTop: '10px' }}
            >
              Delete
            </Button>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default ViewVideos;
