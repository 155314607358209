// firebaseConfig.js
import { initializeApp, getApps } from 'firebase/app';
import { getStorage } from 'firebase/storage';

const firebaseConfig = {
    apiKey: "AIzaSyA4m8hFC9j_S76WYKS3URlM3bSJhOf_EGA",
    authDomain: "myiptvng.firebaseapp.com",
    databaseURL: "https://myiptvng-default-rtdb.firebaseio.com",
    projectId: "myiptvng",
    storageBucket: "myiptvng.appspot.com",
    messagingSenderId: "28737901292",
    appId: "1:28737901292:web:a185c1017e65cd856dcbc9",
    measurementId: "G-5SNMG4TW4J"
  }

let app;
if (!getApps().length) {
  app = initializeApp(firebaseConfig);
} else {
  app = getApps()[0];
}

const storage = getStorage(app);

export { storage };
