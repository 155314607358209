import React, { Component } from 'react'
import App from 'base-shell/lib'
import config from './config'

// 13131
import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import { logEvent } from 'firebase/analytics';


const app = initializeApp(config.firebase.prod.initConfig);

const analytics = getAnalytics(app);

logEvent(analytics, 'page_view');
logEvent(analytics, 'login');
// Log a purchase event with custom parameters
logEvent(analytics, 'purchase', {
  currency: 'USD',
  value: 10.99,
  items: [{ id: 'item1', name: 'Product 1' }],
});

console.log('App')

export default class Demo extends Component {
  render() {
    return <App config={config} />
  }
}
